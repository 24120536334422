import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { Provider } from 'react-redux'
import { store } from './redux/store'
import DocumentMeta from 'react-document-meta';

import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root')

const meta = {
    title: 'GoldSand Info Chart, GoldSand 数据浏览器，全新DeFi流动性交易平台, 流动性挖矿, 去中心化应用, DeFi, Swap, Liquidity Mining Data',
    description: 'Get the real-time GoldSand transaction data on GoldSand chart. Trade USDT, ETH, SAP on GoldSand.',
    canonical: 'http://info.GoldSandtrade.com',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'GoldSand Info Chart, GoldSand Info, GoldSand data browser, GoldSand Data, GoldSand Charts, GoldSand, 全新DeFi流动性交易平台,流动性挖矿, 去中心化应用, DeFi, Swap, Liquidity Mining, 闪兑APP, AMM交易, 一体化挖矿,GoldSand官网, SAP GoldSand'
        }
    }
}

ReactDOM.render(
    <Provider store={store}>
        <DocumentMeta {...meta}>
            <App />
        </DocumentMeta>
    </Provider>,
    rootElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
