import {
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { withTranslation } from 'react-i18next';
import { renderCoinWithCircBG } from "../../utils/RenderIcon";
import { FormatNumber } from "../../utils/FormatNumber";
import { roundingDown } from "../../utils/RoundingDown";
import { isMobile } from "react-device-detect";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { move } from "../../utils/Move";
import {useHistory} from "react-router-dom";

function TVLRank({ t, width, showPage, rowCount }) {
    const StyledTableCell = withStyles((theme) => ({
        root: {
            borderBottom: 'none',
            // textAlign: 'center'
        },
        head: {
            backgroundColor: 'transparent',
            color: '#38404D',
            borderBottom: 'solid thin #29353f',
            borderWidth: 1.6,
            fontSize: 16,
            padding: '35px 20px',
            // paddingLeft: 80

        },
        body: {
            // background:'red',
            fontSize: 16,
            color: 'white',
            padding: '25px 20px',
            // paddingLeft: 80
        }
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            backgroundColor: 'transparent'
        },
    }))(TableRow);

    const useStyles = makeStyles({
        table: {
            // minWidth: 700,
        },
        arrowUp: {
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '8px solid green',
            marginRight: 15
        },
        arrowDown: {
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: '8px solid red',
            marginRight: 15
        },
        pagination: {
            color: 'white',
            // width: '100%',
            border: 'none',
            fontSize: isMobile && showPage ? 10 : 16,
            // alignSelf: ',
            // backgroundColor:"red",
            alignItems: 'flex-start'

        },
        caption: {
            // padding: 8,
            fontSize: isMobile && showPage ? 0 : 16,
        },
        toolbar: {
            "& > p:nth-of-type(2)": {
                fontSize: isMobile && showPage ? 10 : 16,
            }
        }
    });

    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const initApidata = () => {
    }

    const rankInfo = [
        {
            address: "bc1pusyyslevc5hwrafe20kny3gnqhgcp6lde9l4r0n8slqpw79389gqw7w0pt",
            stakedAmount: "12903821903.8721",
            points: "250000001238271037210830",
            updatedAt: "2024-06-20 01:00:00.0"
        },
        {
            address: "bc1pjdxfynlellqp0kk3q6xc8kzcypyd9ssr6zfadve8td6w52etz6eq4la0ap",
            stakedAmount: "12729173223.003",
            points: "1249012740912223217",
            updatedAt: "2024-06-20 01:00:00.0"
        },
        {
            address: "bc1pacj06mtux83w564t89hdwp47w9jlle0dvgpacpce8ah9w2th0dqs72fgc2",
            stakedAmount: "10281082912.1233",
            points: "21093782109371209",
            updatedAt: "2024-06-20 01:00:00.0"
        },
        {
            address: "bc1p62drtp6xlx72qtyzf89k79lj6g9q705zus3yyruu63zew9j8vzjsfqkecw",
            stakedAmount: "9087128111.0023",
            points: "123213213123213",
            updatedAt: "2024-06-20 01:00:00.0"
        },
        {
            address: "bc1pwlncce783k3ejr86pg4u6pp869m7ar2vrp5tkkr6vq22cke4wpuqncvt73",
            stakedAmount: "8812102817.0001",
            points: "42424242424",
            updatedAt: "2024-06-20 01:00:00.0"
        },
        {
            address: "bc1p3sy2drpy5kf4x85pr3xshpkmyvrn7w79rj282kpxuapaehkp3g0qlp2z6d",
            stakedAmount: "67001928.0126",
            points: "1232131231",
            updatedAt: "2024-06-20 01:00:00.0"
        },
        {
            address: "bc1p98h3eu52n4uq4x0q56ypcr509zvu85h06dwvkv3xpegckmp64nqqhxds2q",
            stakedAmount: "10000228.1234",
            points: "2232321",
            updatedAt: "2024-06-20 01:00:00.0"
        }
    ]

    useEffect(() => {
        initApidata()
        return () => {
        }
    }, [])

    const cellContainer = (item, index) => {
        return (
            <div style={{ margin: 'auto', marginLeft:24, borderRadius: 16, width: 260, marginBottom: isMobile && showPage ? 0 : 24, marginTop: isMobile && showPage ? 24 : 0, height: 110, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#161923', justifyContent: 'space-between' }}>
                <div style={{ width: '91%', display: 'flex', justifyContent: 'space-between', marginTop: 16,fontSize: 8, color:'white' }}>
                    <div style={{width:'55%'}}>
                        {index + 1}
                    </div>
                    <div style={{width:'40%', textAlign: 'right'}}>
                        {`${item.address.slice(0, 5)}...${item.address.slice(item.address.length - 4)}`}
                    </div>
                </div>

                <div style={{ width: '91%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 16 }}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width:"35%"}}>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>
                            {t('tvlAmount')}
                        </Typography>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: 'white' }}>
                            {roundingDown(item.stakedAmount, 2)}
                        </Typography>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width:"35%"}}>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>
                            {t('tvlPoints')}
                        </Typography>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: 'white' }}>
                            {item.points}
                        </Typography>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width:"20%" }}>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>
                            {t('updatedTime')}
                        </Typography>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: 'white', wordBreak: 'break-word' }}>
                            {item.updatedAt}
                        </Typography>
                    </div>


                </div>
            </div >
        );
    }
    return (
        <div style={{
            width: width,
            height: isMobile && showPage ? "95%" : '80%',
            alignItems: isMobile && showPage ? "flex-start" : 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: isMobile ? 12 : 50,
            // marginBottom: 24,
            // backgroundColor: 'yellow'

        }}>

            {

                isMobile && !showPage ?
                    <div style={{ width: "100%", marginTop: 24, overflow: 'hidden' }}>
                        <ScrollMenu>
                            {
                                rankInfo.map((item, index) =>
                                    cellContainer(item, index)
                                )
                            }
                        </ScrollMenu>
                    </div>
                    :


                    <TableContainer component={Paper}


                                    style={{
                                        background: 'linear-gradient(180deg, #140e11,#0f0e14)',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 20,
                                        border: 'solid thin #1E1D22',
                                        height: '80%'

                                    }}>
                        <Table className={classes.table} aria-label="customized table">
                            {isMobile && showPage ?
                                null
                                :
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left" style={{paddingLeft:80}}>{t('rank')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('tvlAmount')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('tvlAddress')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('tvlPoints')}</StyledTableCell>
                                        <StyledTableCell align="left" style={{paddingRight:80}}>{t('updatedTime')}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                            }

                            <TableBody>
                                {rankInfo.map((row, index) => (

                                    isMobile && showPage ?
                                        cellContainer(row, index)

                                        :

                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left" style={{paddingLeft:80}}>{index + 1}</StyledTableCell>
                                            <StyledTableCell align="left">{roundingDown(row.stakedAmount, 2)}</StyledTableCell>
                                            <StyledTableCell align="left">{row.address}</StyledTableCell>
                                            <StyledTableCell align="left">{row.points}</StyledTableCell>
                                            <StyledTableCell align="left">{row.updatedAt}</StyledTableCell>
                                        </StyledTableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }

        </div>
    )
}

export default withTranslation()(TVLRank);
