import React, {useEffect, useState} from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import PoolWrapper from './components/Pools/PoolWrapper';
import Summary from './components/Summary/Summary';
import TokenWrapper from './components/Tokens/TokenWrapper';

import {useDispatch, useSelector} from "react-redux";
import {withTranslation} from "react-i18next";
import i18n from './i18n';
import useWindowDimensions from "./utils/WindowDimensions";
import TokenDetails from "./components/Tokens/TokenDetails";
import ScrollToTop from "./utils/ScrollToTop";
import TradeWrapper from "./components/Trade/TradeWrapper";
import TVLWrapper from "./components/TVLRank/TVLWrapper";

function App({t, props}) {
    const [navBarHeight, setNavBarHeight] = useState(0)

    const window = useWindowDimensions();
    const WIDTH = window.width
    const HEIGHT = window.height
    return (
        <Router>
            <ScrollToTop>
                <div className='root__container' style={{width: '100%'}}>
                    <Navbar width={WIDTH} style={{width: '100%'}}/>
                    <Switch>
                        <Route exact path='/' exact>
                            <Summary height={HEIGHT} width={WIDTH} navBarHeight={navBarHeight}/>
                        </Route>
                        <Route path='/pools' exact>
                            <PoolWrapper height={HEIGHT} width={WIDTH} navBarHeight={navBarHeight}/>
                        </Route>
                        <Route path='/tokens' exact>
                            <TokenWrapper height={HEIGHT} width={WIDTH} navBarHeight={navBarHeight}/>
                        </Route>
                        <Route path='/tokens/:token' exact>
                            <TokenDetails height={HEIGHT} width={WIDTH} navBarHeight={navBarHeight}/>
                        </Route>
                        <Route path='/trade' exact>
                            <TradeWrapper height={HEIGHT} width={WIDTH} navBarHeight={navBarHeight}/>
                        </Route>
                        <Route path='/rank' exact>
                            <TVLWrapper height={HEIGHT} width={WIDTH} navBarHeight={navBarHeight}/>
                        </Route>
                    </Switch>
                </div>
            </ScrollToTop>
        </Router>
    )
}

export default withTranslation()(App);
