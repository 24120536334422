import {
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { withTranslation } from 'react-i18next';
import { renderCoinWithCircBG } from "../../utils/RenderIcon";
import { FormatNumber } from "../../utils/FormatNumber";
import { roundingDown } from "../../utils/RoundingDown";
import './Tokens.css'
import { isMobile } from "react-device-detect";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { move } from "../../utils/Move";
import {useHistory} from "react-router-dom";

function Tokens({ t, width, showPage, rowCount }) {
    const StyledTableCell = withStyles((theme) => ({
        root: {
            borderBottom: 'none',
            // textAlign: 'center'
        },
        head: {
            backgroundColor: 'transparent',
            color: '#38404D',
            borderBottom: 'solid thin #29353f',
            borderWidth: 1.6,
            fontSize: 16,
            padding: '35px 30px',
            paddingLeft: 80

        },
        body: {
            // background:'red',
            fontSize: 16,
            color: 'white',
            padding: '25px 30px',
            paddingLeft: 80
        }
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            backgroundColor: 'transparent'
        },
    }))(TableRow);

    const useStyles = makeStyles({
        table: {
            // minWidth: 700,
        },
        arrowUp: {
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '8px solid green',
            marginRight: 15
        },
        arrowDown: {
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: '8px solid red',
            marginRight: 15
        },
        pagination: {
            color: 'white',
            // width: '100%',
            border: 'none',
            fontSize: isMobile && showPage ? 10 : 16,
            // alignSelf: ',
            // backgroundColor:"red",
            alignItems: 'flex-start'

        },
        caption: {
            // padding: 8,
            fontSize: isMobile && showPage ? 0 : 16,
        },
        toolbar: {
            "& > p:nth-of-type(2)": {
                fontSize: isMobile && showPage ? 10 : 16,
            }
        },
        buttonHover:{
            '&:hover': {
                backgroundColor:'rgba(255,255,255,0.1)'
            }
        }
    });

    const history = useHistory();
    const classes = useStyles();
    const { tokenIcons, tokensInfo } = useSelector(state => state.details)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(rowCount === undefined ? 5 : rowCount);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //Reorder data
    let top = tokensInfo.find(item => item.token === 'SAP')

    tokensInfo.sort((a, b) => {
        if (a.allvolume >= b.allvolume)
            return -1
        if (a.allvolume < b.allvolume)
            return 1
        return 0
    })

    if (top !== undefined) {
        move(tokensInfo, tokensInfo.indexOf(top), 0)
    }

    const cellContainer = (item) => {
        return (
            <div onClick={()=>{history.push('/tokens/'+item.token)}} style={{cursor:'pointer', marginLeft: 24, borderRadius: 16, width: 260, marginBottom: isMobile && showPage ? 0 : 24, marginTop: isMobile && showPage ? 24 : 0, height: 110, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#161923', justifyContent: 'space-between' }}>
                <div style={{ width: '91%', display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>

                    {renderCoinWithCircBG(item.token, false, 1, tokenIcons)}
                    <div style={{ borderRadius: 24, backgroundColor: parseFloat(item.increase) > 0 ? "#C7FFE8" : '#FFCCC3', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography style={{ fontSize: 10, fontWeight: 'bold', color: item.increase > 0 ? 'green' : 'red', marginLeft: 8, marginRight: 8 }}>
                            {`${roundingDown(item.increase, 2)}%`}
                        </Typography>
                    </div>
                </div>

                <div style={{ width: '91%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 16 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>
                            {t('tableLiquidity')}
                        </Typography>
                        <Typography style={{ fontSize: 10, fontWeight: 'bold', color: 'white' }}>
                            {`$${roundingDown(item.liquidity, 2)}`}
                        </Typography>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>
                            {t('tableDailyUSD')}
                        </Typography>
                        <Typography style={{ fontSize: 10, fontWeight: 'bold', color: 'white' }}>
                            {`$${FormatNumber(roundingDown(item.allvolume, 2))}`}
                        </Typography>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography style={{ fontSize: 8, fontWeight: 'bold', color: '#5B5D64' }}>
                            {t('tableCurrentPrice')}
                        </Typography>
                        <Typography style={{ fontSize: 10, fontWeight: 'bold', color: 'white' }}>
                            {`$${roundingDown(item.price, 8)}`}
                        </Typography>
                    </div>


                </div>
            </div >
        );
    }

    return (
        <div style={{
            width: width,
            height: isMobile && showPage ? "95%" : '80%',
            alignItems: isMobile && showPage ? "flex-start" : 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: isMobile ? 12 : 50,
            // marginBottom: 24,
            // backgroundColor: 'yellow'

        }}>

            {

                isMobile && !showPage ?
                    <div style={{ width: "100%", marginTop: 24, overflow: 'hidden' }}>
                        <ScrollMenu>
                            {
                                (rowsPerPage > 0 && tokensInfo.length > 0
                                    ? tokensInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : tokensInfo
                                ).map((item, index) =>
                                    cellContainer(item)
                                )
                            }
                        </ScrollMenu>
                    </div>
                    :


                    <TableContainer component={Paper}


                        style={{
                            background: 'linear-gradient(180deg, #140e11,#0f0e14)',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 20,
                            border: 'solid thin #1E1D22',
                            height: '80%'

                        }}>
                        <Table className={classes.table} aria-label="customized table">
                            {isMobile && showPage ?
                                null
                                :
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{t('tableToken')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('tableLiquidity')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('tableDailyUSD')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('tableCurrentPrice')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('tableDailyPriceChange')}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                            }

                            <TableBody>
                                {(rowsPerPage > 0 && tokensInfo.length > 0
                                    ? tokensInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : tokensInfo
                                ).map((row, index) => (

                                    isMobile && showPage ?
                                        cellContainer(row)

                                        :

                                        <StyledTableRow className={classes.buttonHover} key={index} onClick={()=>{history.push('/tokens/'+row.token)}} style={{cursor:'pointer'}}>
                                            <StyledTableCell component="th" scope="row">
                                                {renderCoinWithCircBG(row.token, false, 1, tokenIcons)}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{`$${roundingDown(row.liquidity, 2)}`}</StyledTableCell>
                                            <StyledTableCell align="left">{`$${FormatNumber(roundingDown(row.allvolume, 2))}`}</StyledTableCell>
                                            <StyledTableCell align="left">{`$${roundingDown(row.price, 8)}`}</StyledTableCell>
                                            <StyledTableCell align="left">
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    {
                                                        parseFloat(row.increase) > 0 ?
                                                            <div className={classes.arrowUp}></div> : <div className={classes.arrowDown}></div>
                                                    }
                                                    <Typography style={{ color: row.increase > 0 ? 'green' : 'red' }}>{`${roundingDown(row.increase, 2)}%`}</Typography>
                                                </div>

                                            </StyledTableCell>
                                        </StyledTableRow>

                                ))}
                            </TableBody>
                            {
                                showPage ?
                                    <TableFooter style={{}}>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                count={tokensInfo.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'rows per page' },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                classes={{ root: classes.pagination, caption: classes.caption, toolbar: classes.toolbar }}
                                            />
                                        </TableRow>
                                    </TableFooter> : null
                            }
                        </Table>
                    </TableContainer>
            }

        </div>
    )
}

export default withTranslation()(Tokens);
