import { makeStyles, Typography, } from '@material-ui/core';
import { FormatNumber } from "../../utils/FormatNumber";
import { withTranslation } from 'react-i18next';
import { createContainer, VictoryAxis, VictoryChart, VictoryLabel, VictoryLine } from 'victory'
import { useSelector } from 'react-redux';
import { roundingDown } from "../../utils/RoundingDown";
import { isMobile } from "react-device-detect";

function LiquiditySummary({ width }) {
    const useStyles = makeStyles((theme) => ({
        root: {
        },
    }));


    const { hourlyInfo } = useSelector(state => state.details)
    const data = hourlyInfo.map(item => {
        return { x: item.time, y: item.liquidity }
    })

    const liquidity = hourlyInfo.map(item => {
        return item.liquidity
    })

    const minLiquidity = Math.min.apply(Math, liquidity)
    const maxLiquidity = Math.max.apply(Math, liquidity)
    const range = maxLiquidity - minLiquidity
    const classes = useStyles();

    const times = hourlyInfo.map(item => {
        return item.time
    })

    let dates = []
    if (times.length > 0) {
        let current = times[0]
        dates.push(current.slice(0, 10))
        for (let i = 0; i < times.length; i++) {
            if (current.slice(0, 10) !== times[i].slice(0, 10)) {
                current = times[i]
                dates.push(current.slice(0, 10))
            }
        }
    }

    const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");
    return (
        <div style={{
            background: 'linear-gradient(#171d21,#0f0e14)',
            borderRadius: 20,
            width: width,
            display: 'flex',
            flexDirection: 'column',
            marginTop: isMobile ? 12 : 50,
            marginBottom: 0
        }}
        >
            <Typography style={{
                fontSize: isMobile ? 14 : 40,
                color: 'white',
                fontWeight: 'bold',
                paddingLeft: isMobile ? 24 : 40,
                paddingTop: isMobile ? 24 : 40,
            }}>
                {
                    `$${FormatNumber(Math.floor(liquidity[liquidity.length - 1]))}`
                }
            </Typography>
            <Typography style={{
                fontSize: isMobile ? 10 : 25,
                color: 'white',
                fontWeight: 'bold',
                paddingLeft: isMobile ? 24 : 40,
                paddingTop: 5,
                paddingBottom: isMobile ? 0 : 20
            }}>
                {
                    dates.length > 0 ? dates[dates.length - 1].slice(5, 10) : null
                }
            </Typography>
            <VictoryChart
                height={isMobile ? width * 0.6 : width * 0.2}
                width={width * 0.7}
                containerComponent={
                    <VictoryZoomVoronoiContainer
                        labels={({ datum }) => `$${FormatNumber(roundingDown(datum.y, 2))} \n ${datum.x.slice(5)}`}
                    />
                }
            >
                <VictoryLine
                    interpolation="monotoneX"
                    data={data}
                    style={{
                        data: { stroke: "url(#myGradient)" },
                    }}
                />
                <VictoryAxis crossAxis
                    standalone={false}
                    tickCount={isMobile ? 4 : 8}
                    // tickValues={dates}
                    tickFormat={(t) => `${typeof t === 'string' && t.length > 11 ? isMobile ? t.slice(5, 10) : t.slice(0, 10) : t}`}
                    // label={"Time slot (UTC)"}
                    style={{
                        axisLabel: { fontSize: 15, padding: 20 },
                        axis: { stroke: "none" }

                    }}
                    axisLabelComponent={
                        <VictoryLabel
                            dy={1}
                            dx={10}
                            angle={0}
                            style={{
                                fill: "#a6a7aa",
                                fontSize: 10,
                                // fontFamily: "'Courier New', Courier, monospace"
                            }}
                        />
                    }
                    tickLabelComponent={
                        <VictoryLabel
                            style={{
                                fill: "#545454",
                                fontSize: 15,
                                // fontFamily: "'Courier New', Courier, monospace"
                            }}
                        />
                    }
                />
                <svg>
                    <defs>
                        <linearGradient id="myGradient"
                            x1="0%" y1="0%" x2="100%" y2="0%"
                        >
                            <stop offset="0%" stopColor="#47dca8" />
                            <stop offset="25%" stopColor="#41cab8" />
                            <stop offset="50%" stopColor="#49a6c5" />
                            <stop offset="75%" stopColor="#5886cf" />
                            <stop offset="100%" stopColor="#4c60a7" />
                        </linearGradient>
                    </defs>
                </svg>
            </VictoryChart>
        </div>
    )
}

export default withTranslation()(LiquiditySummary);
