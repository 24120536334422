import { makeStyles } from '@material-ui/core';
import React, {useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import TVLRank from "./TVLRank";
import {detailActions} from "../../redux/actions/detailActions";
import {useDispatch} from "react-redux";


function TVLWrapper({ t, width, height, navBarHeight }) {

    const useStyles = makeStyles({
        table: {
            minWidth: 700,
        },
    });

    const dispatch = useDispatch();
    const initApidata = () => {
    }

    useEffect(() => {
        initApidata()
        return () => {
        }
    }, [])

    return (
        <div style={{
            display: 'flex',
            width: width,
            // height: height - navBarHeight,
            minHeight:1500,
            alignItems: 'flex-start',
            justifyContent: 'center',
            // background: 'linear-gradient(135deg, #1b1523, #0f0e14)'
            // flexDirection:'column'
        }}>
            <TVLRank width={width * 0.8} showPage={true} rowCount={10} />
            {/* <div style={{height:400,width:'100%'}}></div> */}
        </div>
    )
}

export default withTranslation()(TVLWrapper);
