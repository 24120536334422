import React, { useState } from 'react';
import './Navbar.css';
import { withTranslation } from 'react-i18next';
import { NavLink, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Button, ListItem, MenuItem, MenuList, Typography, } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import logo from '../../images/logo.png'
import i18n from '../../i18n';
import moreIcon from '../../images/moreIcon.png'
function Navbar({ height,width, t, sendBackHeight }) {

    const useStyles = makeStyles((theme) => ({
        bar: {
            background: 'yellow'
        },
        navbarDisplayFlex: {
            display: `flex`,
            justifyContent: `space-between`,
            backgroundColor: 'blue',
            width: '100%'
        },
        navDisplayFlex: {
            display: `flex`,
            justifyContent: `space-between`,
        },
        linkText: {
            textDecoration: `none`,
            textTransform: `uppercase`,
            color: `white`,
            alignSelf: 'center'
        },
        selected: {
            // textDecoration: `none`,
            // color: `green`,
            // backgroundColor:'green',
            borderRadius: 8,
        },
        logo: {
            width: width > 1000 ? 200 : width * 0.2,
        },
        langBtn: {
            height: 30,
            alignSelf: 'center',
            background: 'transparent',
            color: 'white'
        },
        addrBtn: {
            height: 30,
            alignSelf: 'center',
            background: '#2435AC',
            color: 'white',
            fontSize: width > 350 ? 15 : '0.5rem'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: 'white',
            border: 'transparent',
            // boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: 20,
            textAlign: 'center'
        },
    }));
    const classes = useStyles();
    const history = useHistory();
    const [openMore, setOpenMore] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [currentPath, setCurrentPath] = useState('')


    const handleToggle = () => {
        setOpenMore((prevOpen) => !prevOpen);
    };
    const changeLanguage = (e) => {
        let newLang = i18n.language == 'en' ? 'cn' : 'en'
        i18n.changeLanguage(newLang);
        localStorage.setItem('lng', newLang)
    }
    const navLinks = [
        { title: t('Summary'), path: `/`, name: 'summary' },
        { title: t('Pools'), path: `/pools`, name: 'pools' },
        { title: t('Tokens'), path: `/tokens`, name: 'tokens' },
        { title: t('navTrade'), path: `/trade`, name:'trade'},
        { title: t('tvlRank'), path: `/rank`, name:'rank'}
    ]

    const handleCloseMore = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenMore(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenMore(false);
        }
    }

    const openLayer2 = () => {
        const newWindow = window.open(process.env.REACT_APP_REDIRECT_URL, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const isActive = (match, location, path, name) => {
        if ((location.pathname.includes(name) && name !== 'summary') || (location.pathname === '/' && name === 'summary')) {
            setCurrentPath(path)
        }
        let isActive
        if (location.pathname === path) {
            isActive = true
        } else if (location.pathname.includes('Pools') && path === '/Pools') {
            isActive = true
        } else if (location.pathname.includes('Tokens') && path === '/Tokens') {
            isActive = true
        } else if (location.pathname.includes('Trade') && path === '/Trade') {
            isActive = true
        } else if (location.pathname.includes('Trade') && path === '/Rank') {
            isActive = true
        } else {
            isActive = false
        }

        return isActive
    }

    return (
        <div style={{ flexDirection: 'column', justifyContent: 'space-between', height: 100, alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', height: 100, minWidth: 320 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '86%' }}>
                    {/* {width > 900 ?
                        <Button onClick={() => { history.replace('/') }} aria-label="home" style={{ height: '100%' }}>
                            <img style={{ height: width < 390 ? 48.8 * 0.7 : 65 * 0.7, width: width < 390 ? 238 * 0.7 : 311 * 0.7, marginRight: width < 390 ? null : 24 }} src={logo} />
                        </Button>
                        :
                        null
                    } */}
                    <div style={{ width: 1, height: '80%', backgroundColor: '#EAEAEA' }} />
                    <div style={{ display: 'flex', flexDirection: 'row', height: '100%'}}>
                        {
                            width > 900 ?
                                <div style={{ height: '100%', display: 'flex' }}>
                                    {navLinks.map(({ title, path, name }) => (
                                        <NavLink to={path} key={title} style={{
                                            height: '100%', textDecoration: `none`
                                        }}
                                            inputprops={{ disableUnderline: true }}
                                            activeClassName={classes.selected}
                                            isActive={(match, location) => isActive(match, location, path, name)}
                                        >
                                            <ListItem button style={{ height: '100%' }}>
                                                <Typography style={{ padding: '5px 12px', borderRadius: 3, borderImageSlice: 1, borderImageSource: 'linear-gradient(to right, #48e2b1, #5c7ed3)', borderTop: '0 solid', borderLeft: '0 solid', borderRight: '0 solid', borderBottom: currentPath.includes(name) || currentPath === '/' && name === 'summary' ? '4px solid' : '0 solid', fontSize: 16, color: 'white', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                    {title}
                                                </Typography>
                                            </ListItem>
                                        </NavLink>
                                    ))}
                                </div>
                                :
                                <button className='button__lang'  style={{ backgroundColor: 'transparent', height: '80%', marginLeft: 0 }}
                                    ref={anchorRef}
                                    aria-controls={openMore ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                >

                                    <img style={{ height: 24, width: 24 }} src={moreIcon} />
                                </button>
                        }
                    </div>
                    <div style={{ justifyContent: 'center', marginLeft: 'auto' }}>
                        <button className='button__lang' style={{ height: 38 }}
                            onClick={() => changeLanguage()}
                            variant="contained">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                                <Typography style={{ fontSize: 16, color: 'white', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                    {t('lang')}
                                </Typography>
                            </div>
                        </button>
                        <button className='button__navCell'
                            onClick={() => openLayer2()}
                            variant="contained">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
                                <Typography style={{ fontSize: 16, color: 'white', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                    Layer2
                                </Typography>
                            </div>
                        </button>



                        <div style={{ alignItems: 'center', justifyContent: 'center', marginLeft: 16, marginRight: 40 }}>

                            <Popper open={openMore} anchorEl={anchorRef.current} role={undefined} transition>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleCloseMore}>
                                                <MenuList style={{ zIndex: 999 }} autoFocusItem={openMore} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                    {
                                                        width <= 900 ?
                                                            navLinks.map(({ title, path }) => (
                                                                <NavLink to={path} key={title} style={{
                                                                    height: '100%', textDecoration: `none`
                                                                }}
                                                                    inputprops={{ disableUnderline: true }}
                                                                    isActive={(match, location) => isActive(match, location, path)}
                                                                >
                                                                    <ListItem button style={{ height: '100%' }}>
                                                                        <Typography style={{ fontSize: 12, color: '#18121f', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                                            {title}
                                                                        </Typography>
                                                                    </ListItem>
                                                                </NavLink>
                                                            )) : null
                                                    }
                                                    <MenuItem
                                                        onClick={changeLanguage}
                                                    >
                                                        <Typography style={{ fontSize: 12, color: '#18121f', fontWeight: 'bold', textTransform: 'none', marginLeft: 16, marginRight: 16 }}>
                                                            {t('lang')}
                                                        </Typography>
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(Navbar);
